import React from "react"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./style.scss"
import firebase from "gatsby-plugin-firebase"

const FbPostSlider = (props) => {
  const [posts, setPosts] = React.useState([])

  React.useEffect(() => {
    firebase
      .firestore()
      .collection("profil")
      .doc("posts")
      .get().then(snapshot => {
        console.log(snapshot.data().ids)
        setPosts(snapshot.data().ids.map(val => `https://www.facebook.com/profildancecompany/posts/${val}`))
        if (window.FB) {
          window.FB.XFBML.parse(document.getElementById('sliderek'))
        }
      })
  }, [])

  const slideSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1325,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className="news">
      <Container>
        <h3 className={"text-center aktualnosci-title"}><b>AKTUALNOŚCI</b></h3>
        <Slider {...slideSettings} id="sliderek">
          {
            posts.map(value => <div className="fb-post"
                                    key={value}
                                          data-href={value}
                                          data-width="350"/>)
          }
        </Slider>
      </Container>
    </div>
  )
}

export default FbPostSlider
