import { Button, Col, Container, Jumbotron, Row } from "react-bootstrap"
import Layout from "../layout"
import React from "react"
import "./style.scss"
import { Link } from "gatsby"


const SignUpJumbotron = () => (
  <Jumbotron className={"indexJumbotron"}>
    <Container>
      <Row>
        <Col className="border-right border-lightbrown align-content-end"><Container><h2
          className="text-lightbrown text-center maxwidthjumbotronleft"><span
          className={"smallerFont"}>DOŁĄCZ<br/>DO<br/></span><b>PROFIL<br/>DANCE</b></h2></Container></Col>
        <Col className=" border-left border-lightbrown text-white ">
          <h5 className={"text-left maxwidthjumbotron"}>
            ZAPISZ SIĘ NA NASZE <b>ZAJĘCIA TANECZNE</b> LUB <b>AKROBATYCZNE</b> KRZYSTAJĄC Z FORMULARZA
            REJESTRACYJNEGO!
          </h5>
          <Link to="/zapisy" className="link-no-style">
            <Button variant="outline-lightbrown" className={"maxwidthjumbotron"} size={"lg"} block>ZAPISZ SIĘ</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </Jumbotron>
)

export default SignUpJumbotron
