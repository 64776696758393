import React, { useLayoutEffect } from "react"
import { Image } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global-style.scss"
import FbPostSlider from "../components/fbpostslider/FbPostSlider"
import SignUpJumbotron from "../components/signupjumbotron"

const IndexPage = () => {
  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Profil"
           keywords={[`profil`, `profil dance company`, `taniec`, `zajecia taneczne`, `zajecia taneczne dla dzieci`, `zajecia taneczne dla dzieci warszawa`]}/>
      <Image src={require("../images/new_landing/profil_dance_landing.jpg")} className="mainImage"/>
      <SignUpJumbotron/>
      <FbPostSlider/>
    </Layout>)
}

export default IndexPage
